// /var/www/website2024/thetree.live/myapp/src/components/TechnologySection.js

import React from 'react';
import { Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Correct path for Swiper CSS
import '../styles/TechnologySection.scss'; // Ensure you have the styles imported

const TechnologySection = ({ data }) => {
  return (
   <>
    <Col xs={12} className="technology-section">
      <h2>{data.title}</h2>
      <p>{data.description}</p>
      <Swiper spaceBetween={20} slidesPerView={1}>
        {data.images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image.url} alt={`Technology ${index + 1}`} className="responsive-img" />
          </SwiperSlide>
        ))}
      </Swiper>
    </Col>
   </>
  );
};

export default TechnologySection;
