// /var/www/website2024/thetree.live/myapp/src/components/BioSection.js
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Correct path for Swiper CSS
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is loaded
import '../styles/BioSection.scss';

const BioSection = ({ language }) => {
  const [bioData, setBioData] = useState({});
  const [treeImages, setTreeImages] = useState([]);

  useEffect(() => {
    fetch('/data/bio.json')
      .then(response => response.json())
      .then(data => setBioData(data[language]));

    fetch('/data/treeImages.json')
      .then(response => response.json())
      .then(data => setTreeImages(data));
  }, [language]);

  return (
    <Row className="bio-section">
      <Col xs={12} md={6} className="bio-text">
        <h2>ABOUT THETREE.LIVE</h2>
        <p>{bioData.description}</p>
      </Col>
      <Col xs={12} md={6} className="bio-images">
        <Swiper spaceBetween={10} slidesPerView={1}>
          {treeImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image.url} alt={`Tree ${index + 1}`} className="responsive-img"/>
            </SwiperSlide>
          ))}
        </Swiper>
      </Col>
    </Row>
  );
};

export default BioSection;
