// /var/www/website2024/thetree.live/myapp/src/components/TrackingDetails.js

import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

const TrackingDetails = ({ treeData }) => {
  const [hover, setHover] = useState(false);

  return (
    <Col 
      xs={4} 
      md={4} 
      className={`tracking-text ${hover ? 'hover' : ''}`} 
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}
    >
      <div className="tree-info">
        <h4>{treeData.treeName}</h4>
        <p>{treeData.treeType}</p>
        <p>ID Code: {treeData.idCode}</p>
        <img src={treeData.image} alt={treeData.treeName} />
        <p>{treeData.qrCode}</p>
      </div>
    </Col>
  );
};

export default TrackingDetails;
