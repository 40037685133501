//var/www/website2024/thetree.live/myapp/src/components/Footer.js

import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="App-footer">
      <p>&copy; 2024 THETREE.LIVE CARBON CREDIT TECHNOLOGY. ALL RIGHTS RESERVED. | DEVELOP BY 
        <a href="https://dev.rampagesoft.com/"> RAMPAGESOFT</a>
        </p>
    </footer>
  );
}

export default Footer;
