// /var/www/website2024/thetree.live/myapp/src/components/TrackingSection.js

import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import '../styles/TrackingSection.scss'; // Ensure you have the styles imported
import TrackingDetails from './TrackingDetails';

const TrackingSection = ({ language }) => {
  const [trackingData, setTrackingData] = useState({});

  useEffect(() => {
    fetch('/data/tracking.json')
      .then(response => response.json())
      .then(data => setTrackingData(data[language]));
  }, [language]);

  return (
    <Row className="tracking-section">
      {trackingData.trees && trackingData.trees.map((tree, index) => (
        <TrackingDetails key={index} treeData={tree} />
      ))}
    </Row>
  );
};

export default TrackingSection;

