// /var/www/website2024/thetree.live/myapp/src/App.js

import React, { useState, useEffect } from 'react';
import './App.css';
import 'primereact/resources/themes/saga-blue/theme.css'; // PrimeReact theme
import 'primereact/resources/primereact.min.css'; // PrimeReact core CSS
import 'primeicons/primeicons.css'; // PrimeIcons
import GridSystem from './components/GridSystem';
import BioSection from './components/BioSection';
import TrackingSection from './components/TrackingSection';
import Footer from './components/Footer';
import TechnologySection from './components/TechnologySection';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [language, setLanguage] = useState('en');
  const [technologyData, setTechnologyData] = useState({ title: '', description: '', images: [] });

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    fetch('/data/technology.json')
      .then(response => response.json())
      .then(data => setTechnologyData(data[language]));

      
  }, [language]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Setting up thetree.live | Under development</h1>
      </header>
      <div className="banner">
        <img src="https://afaa.website/s/d56871.webp" alt="thetree" className="banner-img" />
      </div>
      <div className="menu">
        <div className="menu-item">HOME</div>
        <div className="menu-item">TREE</div>
        <div className="menu-item">CARBON CREDIT</div>
        <div className="menu-item">PLANT</div>
        <a className="menu-item" href="https://blog.ttt-website.com/">CONTENT</a>
        <a className="menu-item" href="https://afraapache.com/">ABOUT</a>
        <div className="menu-item" onClick={() => handleLanguageChange('en')}>
          <img src="https://afaa.website/s/ddbd46.png" alt="EN" className="language-icon" />
        </div>
        <div className="menu-item" onClick={() => handleLanguageChange('th')}>
          <img src="https://afaa.website/s/179124.webp" alt="TH" className="language-icon" />
        </div>
      </div>

      <GridSystem>
        <BioSection language={language} />
        <TechnologySection data={technologyData} />
        <TrackingSection language={language} />
      </GridSystem>

      <Footer />
    </div>
  );
}

export default App;
