// /var/www/website2024/thetree.live/myapp/src/components/GridSystem.js
import React from 'react';
import {  Row } from 'react-bootstrap';

const GridSystem = ({ children }) => {
  return (
    <Row>
    {children}
  </Row>
  );
};

export default GridSystem;
